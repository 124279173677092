<template>
    <div class="page-box">
        <div class="header">
            <van-nav-bar
            :title="$t('mining.title')"
            left-arrow
            @click-left="onClickLeft"
            >
            </van-nav-bar>
        </div>
        <div class="maincontent">
            <van-nav-bar></van-nav-bar>
            <div style="display: flex;align-items: center;padding: 0.8rem 0.94rem;background-color: #2351BD;padding-right: 0;color: #FFFFFF;">
                <div style="width: 50%; text-align: left;">
                    <div style="font-size: 0.94rem;margin-bottom: 0.69rem;">USD(USDT)</div>
                    <div style="font-size: 0.75rem;">{{$t('mining.lock-up')}}</div>
                </div>
                <div style="text-align: right;">
                    <div style="font-size: 0.81rem;margin-bottom: 0.69rem;">{{$t('mining.daily-income')}}</div>
                    <div style="font-size: 0.75rem;color: #FFCB70;">{{info.daily_rate_min}}-{{info.daily_rate_max}}%</div>
                </div>
            </div>
            <div style="display: flex;justify-content: space-between;background-color: #406BC6;padding: 1.09rem 0;">
                <div style="flex: 1;">
                    <div style="font-size: 0.94rem;font-weight: bold;color: #FFFFFF;margin-bottom: 0.47rem;">{{info.times}}(day)</div>
                    <div style="font-size: 0.69rem;color: #91AEDF;">{{$t('mining.lock-up-period')}}</div>
                </div>
                <div style="flex: 1;">
                    <div style="font-size: 0.94rem;font-weight: bold;color: #FFFFFF;margin-bottom: 0.69rem;">{{info.low_money}}</div>
                    <div style="font-size: 0.69rem;color: #91AEDF;">{{$t('mining.minimum-single')}}</div>
                </div>
                <div style="flex: 1;">
                    <div style="font-size: 0.94rem;font-weight: bold;color: #FFFFFF;margin-bottom: 0.69rem;">{{info.max_money=='-1'?$t('mining.unlimited'):info.max_money}}</div>
                    <div style="font-size: 0.69rem;color: #91AEDF;">{{$t('mining.single-most')}}</div>
                </div>
            </div>
            <div style="padding: 0.94rem;">
                <div class="row-flex">
                    <div>
                        <div>{{$t('mining.dividend-time')}}</div>
                        <div class="t2">{{$t('mining.daily')}}</div>
                    </div>
                    <div>
                        <div>{{$t('mining.managed-funds')}}</div>
                        <div class="t2">{{$t('mining.return-due')}}</div>
                    </div>
                    <div style="text-align: right;">
                        <div>{{$t('mining.early-redemption')}}</div>
                        <div class="t2">10.00%</div>
                    </div>
                </div>
                <div class="row-flex">
                    <div>
                        <div>{{$t('mining.expected-earnings')}}(USD)</div>
                        <div class="t2">{{minEarnings}}~{{maxEarnings}}</div>
                    </div>
                    <div style="text-align: right;">
                        <div>{{$t('mining.available-assets')}}(USD)</div>
                        <div class="t2">{{balance.usable}}</div>
                    </div>
                </div>
                <div class="amount-box">
                    <div style="font-size: 0.81rem;color: #333848;text-align: left;margin-bottom: 0.78rem;">{{$t('mining.lockup-amount')}}</div>
                    <div class="amount-input-box">
                        <van-field style="flex: 1;" v-model="number" type="number" :placeholder="$t('mining.enter-amount-tip')" />
                        <span style="font-size: 0.69rem;color: #8796A5;padding: 0 0.63rem;">USD</span>
                        <span style="width: 0.03rem;height: 0.94rem;background-color: #EBEBEB;"></span>
                        <span @click="number = balance.usable" style="padding: 0 0.63rem;font-size: 0.75rem;color: #333848;">{{$t('mining.all')}}</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="bottom-box">
            <van-button class="btn" @click="submit" :loading="loading" loading-type="spinner" >{{$t('mining.subscribe')}}</van-button>
        </div>
    </div>
  </template>

<script>
export default {
  data() {
    return {
      lang: localStorage.getItem('lang') || 'zh',
      number: '',
      balance: {
        usd: 0,
        usable: 0
      },
      info: {
        times: 0,
        daily_rate_max: '0',
        daily_rate_min: '0',
        low_money: '0',
        max_money: '-1'
      },
      loading: false
    }
  },
  computed: {
    minEarnings() {
      if (!this.number) return '0.00'
      return ((this.number * this.info.times * this.info.daily_rate_min) / 100).toFixed(2)
    },
    maxEarnings() {
      if (!this.number) return '0.00'
      return ((this.number * this.info.times * this.info.daily_rate_max) / 100).toFixed(2)
    }
  },
  created() {
    this.getbalance()
    this.getinfo()
  },
  methods: {
    async getbalance() {
      const { data } = await this.$http.get('/home/user/index')
      if (data) {
        if (data.code === 200) {
          this.balance = data.data.balance
        }
      }
    },
    async getinfo() {
      const { data } = await this.$http.get('home/miner/infodetail?id=' + this.$route.query.id)
      if (data) {
        if (data.code === 200) {
          this.info = data.data.info
        }
      }
    },
    async submit() {
      const low_money = Number(this.info.low_money)
      if (!this.number) {
        this.$toast.fail(this.$t('mining.enter-amount-tip'))
        return false
      }
      if (Number(this.number) < low_money) {
        this.$toast.fail(this.$t('mining.minimum-single-tip'))
        return false
      }
      if (this.number > Number(this.balance.usable)) {
        this.$toast.fail(this.$t('mining.balance-tip'))
        return false
      }
      this.loading = true
      try {
        const { data } = await this.$http.post('/home/miner/addlist', { id: this.$route.query.id, total: this.number })
        if (data.code === 200) {
          this.$toast.success(this.$t('mining.success'))
          this.$router.push('/subscribeList')
        }else{
          this.$toast.fail(data.msg)
        }
      } catch (error) {
        
      }
      this.loading = false
    },
    onClickLeft() {
      this.$router.go(-1)
    }
  }
}
</script>

  <style lang="less" scoped>
  .page-box{
    min-height: 100vh;
  }
  .maincontent {
    background: #EFF1F3;
    // flex: 1;
    // display: flex;
    // flex-direction: column;
    // overflow-y: auto;
    height: 100vh;
}
.row-flex{
    display: flex;
    padding: 0.94rem;
    background: #F7F7F7;
    border-radius: 0.38rem;
    margin-bottom: 0.63rem;
    >div{
        flex: 1;
        font-size: 0.69rem;
        color: #8796A5;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        text-align: left;
    }
    .t2{
        color: #333848;
        margin-top: 0.78rem;
    }
}
.amount-box{
    padding: 0.94rem;
    background: #F7F7F7;
    border-radius: 0.38rem;
}
.amount-input-box{
    width: 100%;
    border-radius: 0.25rem 0.25rem 0.25rem 0.25rem;
    border: 0.06rem solid #EBEBEB;
    padding: 0.63rem;
    padding-right: 0;
    display: flex;
    align-items: center;
    /deep/ .van-cell{
        padding: 0;
        background-color: transparent;
    }
}
.bottom-box{
    position: fixed;
    bottom: 2.5rem;
    width: 100%;
    padding: 0 0.94rem;
    .btn{
        width: 100%;
        height: 2.5rem;
        line-height: 2.5rem;
        background: #325DE3;
        border-radius: 0.19rem;
        text-align: center;
        font-size: 0.94rem;
        color: #F5F5F5;
    }
  }
</style>
